/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        (function isMobileClass(){

            $(document).ready(function() {

              if($(window).width() < 768) {

                $("body").addClass("is-mobile");

              }

            });

        })();


        (function scrollToMenu() {

          var $menu = $("#js-scroll-menu");

          $menu.on("click","a", function(){
              var $this = $(this),
                  href = $this.attr("href"),
                  topY = $(href).offset().top;

              TweenLite.to($(window), 1, {
                  scrollTo:{
                      y: topY-50,
                      autoKill: true
                  },
                  ease:Power3.easeOut
               });

            return false;
          });

        })();        


        // (function scrollToContent() {

        //   var $menu = $("#jsScroll");

        //   $menu.on("click","a", function(){
        //       var $this = $(this),
        //           href = $this.attr("href"),
        //           topY = $(href).offset().top;

        //       TweenLite.to($(window), 1, {
        //           scrollTo:{
        //               y: topY-120,
        //               autoKill: true
        //           },
        //           ease:Power2.easeOut
        //        });

        //     return false;
        //   });

        // })();


        (function ajaxContent() {

          var pageOutro = function() {
            var heroContent = $('.hero-content');
            var menuContainer =  $('.panel-holder');                  
            var oldContainer = $('.site-content').first();
            var loader = $('.page-loader');
            

            function step_1() {
              $('.scroll-container').addClass('is-loading');
            }            

            var tlOutro = new TimelineLite();

            tlOutro.pause();

            tlOutro.to(menuContainer, 0.4, {
                opacity: 0,
                display:"none",
                ease: Power2.easeIn
            }, 0).staggerFromTo(heroContent, .4, {
                autoAlpha: 1
            }, {
                autoAlpha: 0,
                y: 30,
                ease: Power2.easeInOut
            }, 0).fromTo(oldContainer, .4, {
                y:''
            }, {
                opacity:0,
                ease: Power2.easeInOut,
                onComplete: step_1
            }, .05, "+=.5").fromTo(loader, .4, { 
                autoAlpha: 0
            }, {
                autoAlpha: 1,
                ease: Power2.easeInOut
            }, 0);

            tlOutro.play();

          }

          var pageIntro = function() {

            var pageBody = $('body, html');
            var menuContainer =  $('.panel-holder');                  
            var newContainer = $('.site-content').first();
            var elements = $('.js-hero');
            var heroImg = $('.js-hero-img');
            var heroContent = $('.hero-content');
            var loader = $('.page-loader');

            function step_2() {
              $('.scroll-container').removeClass('is-loading');
            }  

            var tlIntro = new TimelineLite();

            tlIntro.pause();

            

            tlIntro.fromTo(loader, 0.4, {
                autoAlpha: 1
            }, {
                autoAlpha: 0,
                ease: Power2.easeOut
            }, 0).staggerFromTo(elements, .45, {
                autoAlpha: 0
            }, {
                autoAlpha: 1,
                ease: Power2.easeOut
            }, .05, "+=.4").staggerFromTo(heroImg, .25, {
                autoAlpha: 0
            }, {
                autoAlpha: 1,
                ease: Power2.easeOut
            }, 0).staggerFromTo(heroContent, .4, {
                y: 30,
                autoAlpha: 0
            }, {
                y:0,
                autoAlpha: 1,
                ease: Power2.easeInOut,
                onComplete: step_2
            }, 0);

            tlIntro.play();            
              
          }

          function addBlacklistClass() {
            $( 'a' ).each( function() {
              if ( this.href.indexOf('/wp-admin/') !== -1 || 
                 this.href.indexOf('/wp-login.php') !== -1 || 
                 this.href.indexOf('/staff-forum/') !== -1 ) {
                $( this ).addClass( 'no-smoothState' );
              }
            });
          }

          $( function() {

            addBlacklistClass();

            var settings = { 
              anchors: 'a',
              prefetch: true,
              blacklist: '.no-smoothState',
              onStart: { 
                duration: 2000,
                render: function ( $container ) {

                  pageOutro();
                  //$container.addClass( 'is-loading' );

                }
              },
              onReady: {
                duration: 2000,
                render: function ($container, $newContent) {

                  // Inject the new content
                  $container.html($newContent);

                  // Remove your CSS animation reversing class
                  //$container.removeClass( 'is-loading' );                  
                  pageIntro();

                }
              },              
              onAfter: function( $container ) {

                addBlacklistClass();

                var $hash = $( window.location.hash );

                if ( $hash.length !== 0 ) {

                  var offsetTop = $hash.offset().top;

                }

              }
            };

            $( '#site-wrapper' ).smoothState( settings );

          });

        })();

        (function offCanvasMenu() {

          $(document).ready(function() {

              var tlOffcanvas = new TimelineMax({
                  paused: !0
              });
              $(window).width() < 1024 ? tlOffcanvas.fromTo(".panel-holder", .01, {
                  display:"none", 
                  opacity:0,                
                  zIndex: 0                  
              }, {
                  display:"block", 
                  opacity:1,                
                  zIndex: 99
              }).staggerFromTo("#menu-main-menu .menu-item", .45, {
                  x: 145,
                  opacity: 0
              }, {
                  x: 0,
                  opacity: 1,
                  ease: Power2.easeOut
              }, .05, "+=.4").fromTo(".bg-holder .cover-outer", .6, {
                  display:"none", 
                  opacity:0,
                  xPercent: 100,
                  autoAlpha: 1
              }, {
                  display:"block", 
                  opacity:1,
                  xPercent: 0,
                  ease: Power2.easeInOut
              }, 0).fromTo(".bg-holder .cover-inner", .6, {
                  display:"none", 
                  opacity:0,
                  xPercent: -100,
                  autoAlpha: 1
              }, {
                  display:"block", 
                  opacity:1,                
                  xPercent: 0,
                  ease: Power2.easeInOut
              }, 0).fromTo(".bar-holder .cover-outer", .6, {
                  display:"none", 
                  opacity:0,                
                  xPercent: 100,
                  autoAlpha: 1
              }, {
                  display:"block", 
                  opacity:1,                
                  xPercent: 0,
                  ease: Power2.easeInOut
              }, 0).fromTo(".bar-holder .cover-inner", .6, {
                  display:"none", 
                  opacity:0,                
                  xPercent: -100,
                  autoAlpha: 1
              }, {
                  display:"block", 
                  opacity:1,                 
                  xPercent: 0,
                  ease: Power2.easeInOut
              }, 0).fromTo(".menu-extras", .4, {
                  opacity: 0
              }, {
                  opacity: 1
              }) : tlOffcanvas.fromTo(".panel-holder", .01, {
                  display:"none", 
                  opacity:0,                
                  zIndex: 0
              }, {
                  display:"block", 
                  opacity:1,                
                  zIndex: 99
              }).fromTo(".v-line-1", .01, {
                  autoAlpha: 1
              }, {
                  autoAlpha: 0
              }).staggerFromTo("#menu-main-menu .menu-item", .40, {
                  y: 5,
                  opacity: 0
              }, {
                  y: 0,
                  opacity: 1,
                  ease: Power2.easeInOut
              }, .05, "+=.4").fromTo(".bg-holder .cover-outer", .6, {
                  xPercent: -100,
                  autoAlpha: 1
              }, {
                  xPercent: 0,
                  ease: Power2.easeInOut
              }, 0).fromTo(".bg-holder .cover-inner", .6, {
                  xPercent: 100,
                  autoAlpha: 1
              }, {
                  xPercent: 0,
                  ease: Power2.easeInOut
              }, 0).fromTo(".bar-holder .cover-outer", .6, {
                  xPercent: 100,
                  autoAlpha: 1
              }, {
                  xPercent: 0,
                  ease: Power2.easeInOut
              }, 0).fromTo(".bar-holder .cover-inner", .6, {
                  xPercent: -100,
                  autoAlpha: 1
              }, {
                  xPercent: 0,
                  ease: Power2.easeInOut
              }, 0).fromTo(".menu-extras", .4, {
                  opacity: 0
              }, {
                  opacity: 1
              }), $(".menu-trigger").click(function() {
                  McButton.toggleClass("active"), McButton.hasClass("active") ? mcButtonTL.play() : mcButtonTL.reverse(), tlOffcanvas.paused() || tlOffcanvas.reversed() ? tlOffcanvas.play() : tlOffcanvas.reverse()
              });
              var mcButtonTL = new TimelineLite({
                      paused: !0
                  }),
                  McButton = $("[data=hamburger-menu]"),
                  McBar1 = McButton.find("b:nth-child(1)"),
                  McBar3 = McButton.find("b:nth-child(3)");
              mcButtonTL.to(McBar1, .25, {
                  top: "50%",
                  ease: Power1.easeInOut
              }).to(McBar3, .25, {
                  top: "50%",
                  ease: Power1.easeInOut
              }, "-=.235").to(McBar3, .45, {
                  rotationZ: -90,
                  ease: Power2.easeInOut
              }).to(McButton, 1, {
                  rotationZ: -135,
                  ease: Back.easeInOut.config(1)
              }, "-=.58");

          }); 


        })();

        // (function homeHeroContent() {

        //   $(document).ready(function() {

        //       // HOME PAGE CODE
        //       if ($('#ajax-container').hasClass('home') && $(window).width() > 767) {

        //         //console.log("Oh Baby"); 

        //         // Initiate timelines. I am not sure if this is the best way to go about it.
        //         var holder = $('#hero-area'),
        //         circleTL = new TimelineMax(),
        //         dotTL = new TimelineLite(),
        //         mouseTL = new TimelineLite(),
        //         textTL = new TimelineMax(),
        //         circleProgress = 0,
        //         circleRed = $('.circle-red');
        //         //circleTL.to($('.circle-red'), 0,{drawSVG:circleProgress+'%', ease:Power0.easeNone});
        //         textTL.fromTo($('.active-text'), 0.5, {css: {opacity: 0, y: -50}}, {css:{opacity:1, y: 0}, ease:Power2.easeOut});


        //         // Irrelevant clicks and stuff
        //         function checkKey(a) {
        //             a = a || window.event, "38" === a.keyCode || "39" === a.keyCode ? goPrev() : "40" === a.keyCode || "37" === a.keyCode ? goNext() : "27" === a.keyCode && McButton.hasClass("active") && (McButton.removeClass("active"), holder.toggleClass("viewing-menu"), mcButtonTL.reverse(), tlOffcanvas.reverse());
        //         }

        //         function circleSize() {
        //             var a = $(".circle-nav");
        //             a.height("");
        //             var b = a.height();
        //             $(window).width() > 767 ? heroSpace = $(window).height() - 112 : heroSpace = $(window).height() - 82, b > heroSpace ? (a.height(heroSpace), a.width(heroSpace)) : (a.height(""), a.width(""))
        //         }

        //         document.onkeydown = checkKey, circleSize();

        //         $('.arrow.up').click(function(){  // Fire goPrev when the click the up and down arrows
        //             goPrev();
        //         });

        //         $('.arrow.down').click(function(){
        //             goNext();
        //         });

        //         function slideIn() {   // By the time slideIn/slideOut is called, the correct cover and text have been given a class of active-cover and active-text, respectively
        //             var tlCoverIn = new TimelineMax();
        //             tlCoverIn.fromTo(".active-cover .cover-outer",.9, {xPercent: -101, autoAlpha: 1},{xPercent: 0, ease:Power2.easeIn })
        //                 .fromTo(".active-cover .cover-inner",.9, { xPercent: 101, autoAlpha: 1 },{xPercent: 0, ease:Power2.easeIn}, 0)
        //                 .staggerFromTo($('.active-text'), 0.5, {css: {opacity: 0, y: -50}}, {css:{opacity:1, y: 0}, ease:Power2.easeOut});
        //         }

        //         function slideOut() {
        //             var tlCoverOut = new TimelineMax();
        //             tlCoverOut.to(".active-cover .cover-outer", .01, {xPercent: 0, autoAlpha: 1}, 0)
        //                 .to(".active-cover .cover-inner", .01, {xPercent: 0, autoAlpha: 1}, 0)
        //                 .fromTo(".prev-cover .cover-outer",.9, {xPercent: 0, autoAlpha: 1},{xPercent: -101, ease:Power2.easeIn }, 0)
        //                 .fromTo(".prev-cover .cover-inner",.9, { xPercent: 0, autoAlpha: 1 },{xPercent: 101, ease:Power2.easeIn }, 0)
        //                 .staggerFromTo($('.active-text'), 0.5, {css: {opacity: 0, y: 50}}, {css:{opacity:1, y: 0}, ease:Power2.easeOut}, 0.06);
        //         }

        //         function circleForward() { // Move the red path forward by the percentage of the circle that represents where the user is headed
        //             var nextCircle = $('.dot-red.active:last').next();
        //             circleProgress = circleProgress + 12.5;

        //             circleTL.to(circleRed, 1.2,{drawSVG:circleProgress+'%', force3D: true, ease:Power0.easeNone})
        //                 .set(nextCircle, {className:"+=active"});
        //         }

        //         function circleBack() {
        //             var lastRedCircle = $('.dot-red.active:last');
        //             circleProgress = circleProgress - 12.5;
        //             circleTL.set(lastRedCircle, {className:"-=active"})
        //                 .to(circleRed, 1.2, {drawSVG:circleProgress+'%', ease:Power0.easeNone});
        //         }

        //         function textOutNext() { // Slide the cover text up or down depending if they're going forward or backward
        //             textTL.staggerFromTo($('.prev-text'), 0.5, {css: {opacity: 1, y: 0}},  {css:{opacity:0, y: 50}, ease:Power2.easeIn}, -0.07);
        //         }

        //         function textOutPrev() {
        //             textTL.staggerFromTo($('.prev-text'), 0.5, {css: {opacity: 1, y: 0}}, {css:{opacity:0, y: -50}, ease:Power2.easeIn}, 0.07);
        //         }

        //         function goNext() {
        //             if ($('.dot-red:last-child').hasClass('active') || holder.hasClass('animating') || holder.hasClass('to-content') || holder.hasClass('viewing-menu') ) {
        //                 return;  // If they're on the last cover, first cover, viewing the content, or viewing the menu, BAIL
        //             }
        //             holder.addClass('animating'); // set a timeout to disallow more than one sequence at a time
        //             setTimeout(function() {
        //                 holder.removeClass('animating');
        //             }, 1600);

        //             // Mouse out and arrow fade
        //             if (!$('.dot-red:nth-child(2)').hasClass('active') && !$('.arrows-mouse').hasClass('first-forbidden')){  // pretty irrelevant if statements
        //                 mouseTL.set(".arrows-mouse", {className:"+=goodbye-mouse"})
        //                     .fromTo($(".mouse-up"), .5, {css: {opacity: "1"}}, {css:{opacity:"0"}})
        //                     .fromTo($(".arrow-up-path"), .5, {css: {opacity: "0"}}, {css:{opacity:"1"}})
        //                     .fromTo($(".arrow-up-path"), .5, {css: {scale: ".78"}}, {css:{scale: "1"}}, "-=.4");
        //                 setTimeout(function(){
        //                     $('.arrows-mouse').removeClass('untouched');
        //                 }, 1200);
        //             } else if (!$('.dot-red:nth-child(2)').hasClass('active') && $('.arrows-mouse').hasClass('first-forbidden')){
        //                 mouseTL.set(".arrows-mouse", {className:"-=first-forbidden"});
        //             } else if (!$('.dot-red:last-child').hasClass('active') && $('.dot-red:nth-last-child(2)').hasClass('active')){
        //                 mouseTL.set(".arrows-mouse", {className:"+=last-forbidden"});
        //             }
        //             var activeCover = $('.active-cover'),
        //                 prevCover = $('.prev-cover'),
        //                 nextSlide = activeCover.next();

        //             prevCover.removeClass('prev-cover');
        //             activeCover.addClass('prev-cover');        // swap all the classes so that when the textOut/textIn and slideOut/slideIn functions are called they target the right text/cover
        //             activeCover.removeClass('active-cover');
        //             nextSlide.addClass('active-cover');
        //             var activeText = $('.active-text'),
        //                 nextText = activeText.next(),
        //                 prevText = activeText;
        //             nextText.addClass('active-text');
        //             $('.prev-text').not($(this)).removeClass('prev-text');
        //             prevText.removeClass('active-text').addClass('prev-text');

        //             window.requestAnimationFrame(circleForward);  // Once all the classes get placed, move the circle forward, swap the text, the slide the cover
        //             window.requestAnimationFrame(textOutNext);
        //             setTimeout(function() {
        //                 window.requestAnimationFrame(slideIn);
        //             },100);

                
        //         }

        //         function goPrev() {
        //             if (!$('.dot-red:nth-child(2)').hasClass('active') || holder.hasClass('animating') || holder.hasClass('to-content') || holder.hasClass('viewing-menu')) {
        //                 return;
        //             }
        //             holder.addClass('animating');
        //             setTimeout(function() {
        //                 holder.removeClass('animating');
        //             },1600);

        //             if ($('.dot-red:nth-child(2)').hasClass('active') && !$('.dot-red:nth-child(3)').hasClass('active')){
        //                 mouseTL.set(".arrows-mouse", {className:"+=first-forbidden"}, "+=.1");
        //             } else if ($('.dot-red:last').hasClass('active')){
        //                 mouseTL.set(".arrows-mouse", {className:"-=last-forbidden"});
        //             }
        //             var activeCover = $('.active-cover'),
        //                 prevCover = $('.prev-cover'),
        //                 prevSlide = activeCover.prev();


        //             prevCover.removeClass('prev-cover');
        //             activeCover.addClass('prev-cover');
        //             activeCover.removeClass('active-cover');
        //             prevSlide.addClass('active-cover');
        //             var activeText = $('.active-text'),
        //                 nextText = activeText.prev(),
        //                 prevText = activeText;
        //             nextText.addClass('active-text');
        //             $('.prev-text').not($(this)).removeClass('prev-text');
        //             prevText.removeClass('active-text').addClass('prev-text');
        //             window.requestAnimationFrame(circleBack);
        //             window.requestAnimationFrame(textOutPrev);
        //             setTimeout(function() {

        //                 window.requestAnimationFrame(slideOut);
        //             },100);
        //         }
        //         // $(window).on('wheel', function(e) { // on wheel, either goNext() or goPrev() depending on which way they scrolled
        //         //     var delta = e.originalEvent.deltaY;

        //         //     if ( holder.hasClass('animating') || holder.hasClass('to-content') || holder.hasClass('viewing-menu') ) {
        //         //         return false;
        //         //     }

        //         //     if (delta > 0) {
        //         //         window.requestAnimationFrame(goNext);
        //         //     } else if (delta < 0) {
        //         //         window.requestAnimationFrame(goPrev);
        //         //     }
        //         // });



        //         //They're clicking a dot to get around, but the same jank occurs in the same way so not relevant
        //         $(".st7").click(function() {
        //             function k() {
        //                 circleTL.to(circleRed, 1.2, {
        //                     drawSVG: circleProgress + "%",
        //                     force3D: !0,
        //                     ease: Power0.easeNone
        //                 });
        //             }

        //             // function l() {
        //             //     console.log(c), c < 0 ? $(".dot-red:eq(" + a + ")").hasClass("active") ? $($(".dot-red.active").get().reverse()).each(function(a) {
        //             //         a < c * -1 && a > 0 ? dotTL.to($(this), 0, {
        //             //             className: "-=active",
        //             //             delay: -d,
        //             //             ease: Power0.easeNone
        //             //         }) : 0 === a && dotTL.to($(this), 0, {
        //             //             className: "-=active",
        //             //             delay: -(a * d),
        //             //             ease: Power0.easeNone
        //             //         })
        //             //     }) : $(".dot-red.active").each(function(a) {
        //             //         a <= c * -1 && dotTL.to($(this), 0, {
        //             //             className: "-=active",
        //             //             delay: a * d,
        //             //             ease: Power0.easeNone
        //             //         })
        //             //     }) : c > 0 && $(".dot-red:not(.active)").each(function(a) {
        //             //         a <= c - 1 && dotTL.set($(this), {
        //             //             className: "+=active",
        //             //             delay: d
        //             //         })
        //             //     });
        //             // }
        //             var a = $(this).index(),
        //                 b = $(".dot-red.active:last").index();
        //             if (!(holder.hasClass("animating") || holder.hasClass("to-content") || holder.hasClass("viewing-menu"))) {
        //                 var c = a - b,
        //                     d = 1.2 / c;
        //                 if (a > b) {
        //                     if ($(".dot-red:last-child").hasClass("active")) return;
        //                     circleProgress += 12.5 * c, holder.addClass("animating"), setTimeout(function() {
        //                         holder.removeClass("animating")
        //                     }, 1500), $(".arrows-mouse").hasClass("untouched") ? (mouseTL.set(".arrows-mouse", {
        //                         className: "+=goodbye-mouse"
        //                     }).fromTo($(".mouse-up"), .5, {
        //                         css: {
        //                             opacity: "1"
        //                         }
        //                     }, {
        //                         css: {
        //                             opacity: "0"
        //                         }
        //                     }).fromTo($(".arrow-up-path"), .5, {
        //                         css: {
        //                             opacity: "0"
        //                         }
        //                     }, {
        //                         css: {
        //                             opacity: "1"
        //                         }
        //                     }).fromTo($(".arrow-up-path"), .5, {
        //                         css: {
        //                             scale: ".78"
        //                         }
        //                     }, {
        //                         css: {
        //                             scale: "1"
        //                         }
        //                     }, "-=.4"), setTimeout(function() {
        //                         $(".arrows-mouse").removeClass("untouched")
        //                     }, 500)) : 6 == a ? $(".arrows-mouse").addClass("last-forbidden").removeClass("first-forbidden") : $(".arrows-mouse").removeClass("first-forbidden");
        //                     var e = $(".active-cover"),
        //                         f = $(".prev-cover"),
        //                         g = $(".cover").eq(a);
        //                     f.removeClass("prev-cover"), e.addClass("prev-cover"), e.removeClass("active-cover"), g.addClass("active-cover");
        //                     var h = $(".active-text"),
        //                         i = h;
        //                     $(".js-texty:nth-child(" + (a + 1) + ")").each(function() {
        //                         $(this).addClass("active-text")
        //                     }), $(".prev-text").not($(this)).removeClass("prev-text"), i.removeClass("active-text").addClass("prev-text"), window.requestAnimationFrame(textOutNext), setTimeout(function() {
        //                         window.requestAnimationFrame(slideIn)
        //                     }, 100)
        //                 } else if (a < b) {
        //                     if (!$(".dot-red:nth-child(2)").hasClass("active")) return;
        //                     circleProgress += 12.5 * c, holder.addClass("animating"), setTimeout(function() {
        //                         holder.removeClass("animating")
        //                     }, 1500), 0 == a ? $(".arrows-mouse").addClass("first-forbidden").removeClass("last-forbidden") : $(".arrows-mouse").removeClass("last-forbidden");
        //                     var e = $(".active-cover"),
        //                         f = $(".prev-cover"),
        //                         j = $(".cover").eq(a);
        //                     f.removeClass("prev-cover"), e.addClass("prev-cover"), e.removeClass("active-cover"), j.addClass("active-cover");
        //                     var h = $(".active-text"),
        //                         i = h;
        //                     $(".js-texty:nth-child(" + (a + 1) + ")").each(function() {
        //                         $(this).addClass("active-text")
        //                     }), $(".prev-text").not($(this)).removeClass("prev-text"), i.removeClass("active-text").addClass("prev-text"), window.requestAnimationFrame(textOutPrev), $(".cover:not(.prev-cover):not(.active-cover) .cover-outer, .cover:not(.prev-cover):not(.active-cover) .cover-inner").css({
        //                         visibility: "",
        //                         opacity: ""
        //                     }), setTimeout(function() {
        //                         var a = new TimelineMax;
        //                         a.fromTo(".active-cover .cover-outer", .1, {
        //                             xPercent: -101,
        //                             autoAlpha: 1
        //                         }, {
        //                             xPercent: 0
        //                         }, 0).fromTo(".active-cover .cover-inner", .1, {
        //                             xPercent: 101,
        //                             autoAlpha: 1
        //                         }, {
        //                             xPercent: 0
        //                         }, 0).fromTo(".prev-cover .cover-outer", .9, {
        //                             xPercent: 0,
        //                             autoAlpha: 1
        //                         }, {
        //                             xPercent: -101,
        //                             force3D: !0,
        //                             ease: Power2.easeIn
        //                         }, 0).fromTo(".prev-cover .cover-inner", .9, {
        //                             xPercent: 0,
        //                             autoAlpha: 1
        //                         }, {
        //                             xPercent: 101,
        //                             force3D: !0,
        //                             ease: Power2.easeIn
        //                         }, 0).staggerFromTo($(".active-text"), .5, {
        //                             css: {
        //                                 opacity: 0,
        //                                 y: 50
        //                             }
        //                         }, {
        //                             css: {
        //                                 opacity: 1,
        //                                 y: 0
        //                             },
        //                             force3D: !0,
        //                             ease: Power2.easeOut
        //                         }, .06)
        //                     }, 100)
        //                 }
        //                 window.requestAnimationFrame(k), window.requestAnimationFrame(l)
        //             }
        //         });

        //       } // CLOSE HOME PAGE JS
              

        //   }); 

        // })();



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
